.digi-testimonial-card {
    max-width: 590px;
    height: 274px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.03);
    border-radius: 9px;
    padding: 23px;
    transition: background .5s ease;
    position: relative;
}

.digi-testimonial-card:hover {
    background: linear-gradient(90deg, #2A5AE7 0%, #43E2FF 100%);
}

.digi-testimonial-card:hover .digi-test-card-heading {
    color: #FFFFFF;
}

.digi-testimonial-card:hover .digi-test-card-para {
    color: #FFFFFF;
}
.digi-testimonial-card:hover .digi-test-traineeName {
    color: #FFFFFF;
}

.digi-test-card-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.04em;
    color: #131316;
    margin-bottom: 16px;
}

.digi-test-card-para {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #666666;
    margin-bottom: 14px;
}

.digi-test-card-bottom {
    position: absolute;
    bottom: 30px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.digi-test-traineeName {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #5E606E;
}

/* --------- Media Queries for Mobile ----------- */

@media(max-width:426px){
    .digi-testimonial-card {
        width: 314px;
        height: 224px;
        border-radius: 5px;
        padding: 20px;
        transition: background .5s ease;
    }
    
    .digi-test-card-heading {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 12px;
    }
    
    .digi-test-card-para {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 14px;
    }
    
    .digi-test-card-bottom {
        bottom: 15px;
    }
}
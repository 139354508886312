.talent-hero-section {
    background: url('../Programs/TalentImages/talentBg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


}

.talent-growth {
    margin-top: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 145px;
    padding: 50px 120px;
}

.talent-logo {
    margin-bottom: 10px;
    width: 76.7px;
}

.talent-head {
    max-width: 387px;
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 67px;
    letter-spacing: 0.01em;
    color: #26344E;
    margin-bottom: 20px;
    position: relative;
}

.talentImg1 {
    position: absolute;
    right: 170px;
    bottom: 5px;
}

.talent-para {
    margin-bottom: 50px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #5A5A5A;
}

.talent-btn {
    border: none;
    background: #26344E;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 12px 20px;
    cursor: pointer;
}

.talent-slider-section {
    overflow: hidden;
    background: rgba(38, 34, 98, 0.02);

}

.talent-slider {
    padding: 0px 100px;
    position: relative;

}

.talent-slider-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: #000000;
    text-align: center;
    margin-top: 30px;
}

.talent-parthers-slider{
    padding: 50px;
}

.swiper-button-next,
.swiper-button-prev {
    border: 1px solid #F1AB27;
    border-radius: 5px;
    transform: rotate(135deg);
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 172px;

}

.swiper-button-next {
    right: 100px;
}

.swiper-button-prev {
    left: 100px;
}

.swiper {
    position: static;
    max-width: 1100px;
    margin: 0 70px;
}


.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 20px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #F1AB27;
    padding: 20px;
    transform: rotate(-135deg) translateX(3px);
    font-weight: bold;
}

.swiper-button-prev:after {
    transform: rotate(-135deg) translateX(-2px);
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: #F1AB27;

}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
    color: #fff;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.about-talent-growth-section {
    margin-top: 50px;
    margin-bottom: 32px;
    background-image: url('./TalentImages/about-talent-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 85px;
}

.about-talent-growth-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
}

.about-talent-growth-para {
    max-width: 844px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #666666;
    margin: 0 auto;
}

.about-talent-growth-cards {
    max-width: 1046px;
    margin: 0 auto;
}

.about-talent-growth-cards-top {
    transform: translateX(54px);
    margin-bottom: 40px;
}

.about-talent-growth-cards-bottom {
    padding-bottom: 20px;
}

.about-talent-growth-cards-top,
.about-talent-growth-cards-bottom {
    display: flex;
    justify-content: center;
    gap: 249px;
}

.about-talent-slider{
    display: none;
}

/* ---------- Talent Cards Section ---------- */

.talent-cards-heading{
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    text-align: center;
    color: #26344E; 
    margin-bottom: 40px;
}

.talent-growth-cards {
    background: rgba(38, 34, 98, 0.02);
    /* margin-bottom: 20px; */
    padding-top: 60px;
}

.talent-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    padding-bottom: 40px;
}


.talent-growth-trainees-section {
    background: #FFFFFF;
    padding: 55px 0;
}

.talent-growth-trainees {
    display: flex;
    align-items: center;
    flex-direction: column;
}


.talent-growth-tranees-bottom {
    position: relative;
    display: flex;
    padding-bottom: 70px;
}

.talent-growth-tranees-bottom .swiper-button-next {
    left: 540px;
    top: 300px;
    
}

.talent-growth-tranees-bottom .swiper-button-prev {
    left: 440px;
    top: 300px;
}

.talent-growth-tranees-bottom .swiper {
    position: static;
    margin: 0px;
    /* border: 1px solid red; */
}

.talent-trainees-heading {
    max-width: 660px;
    margin: 0 auto;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: #26344E;
    margin-bottom: 30px;
    text-align: center;
}



/* ---------- Media Queries for Mobile ----------- */

@media (max-width: 426px){

    .talent-hero-section {
        background: url('../Programs/TalentImages/talentBg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    .talent-growth {
        margin-top: 50px;
        display: block;
        gap: 0px;
        padding: 25px 15px;
        max-width: 350px;
        margin: 0 auto;
    }

    .talent-left{
        margin-top: 50px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 430px;
    }
    
    .talent-logo {
        width: 50px;
        height: 30px;
        margin: 0px auto;
        margin-bottom: 5px;
    }
    
    .talent-head {
        max-width: 501px;
        font-size: 36px;
        line-height: 37px;
        letter-spacing: 0.03em;
        text-align: center;
        position: initial;
    }
    
    .talentImg1 {
        display: none;
    }
    
    .talent-para {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        line-height: 18px;
        position: absolute;
        bottom: 35px;
    }
    
    .talent-btn {
        border-radius: 5px;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        padding: 9px 20px;
        max-width: 102px;
        position: absolute;
        bottom: 0;
        left: 35%;
    }

    .talent-right{
        display: block;
    }

    .hero-img{
        position: absolute;
        top: 180px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 203px;
        height: 165px;
        margin: 0 auto;
    }

    /* --------- Talent Slider Section --------- */
    
    .talent-slider {
        padding: 0px 10px;
        position: relative;
    }
    
    .talent-slider-heading {
        display: none;
    }
    
    .talent-parthers-slider{
        padding: 30px 0;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        border: 1px solid #F1AB27;
        border-radius: 5px;
        transform: rotate(135deg);
        width: 17.68px;
        height: 17.68px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: absolute;
        top: 80px;
    
    }
    
    .swiper-button-next {
        right: 20px;
    }
    
    .swiper-button-prev {
        left: 20px;
    }
    
    .talent-slider .swiper {
        position: static;
        max-width: 426px;
        margin: 0 30px;
    }
    
    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-family: swiper-icons;
        font-size: 10px;
        text-transform: none !important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
        color: #F1AB27;
        padding: 8px;
        transform: rotate(-135deg) translateX(1.5px);
        font-weight: bold;
        z-index: 1000;
    }
    
    .swiper-button-prev:after {
        transform: rotate(-135deg) translateX(-1.5px);
    }

    /* ------- Talent About Section ----------- */
    

    .about-talent-growth-section {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 30px 25px;
        background-image: url('./TalentImages/about-talent-bg2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-position-y: 130px;
    }
    
    .about-talent-growth-heading {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    
    .about-talent-growth-para {
        max-width: 314px;
        font-size: 12px;
        line-height: 18px;
        margin: 0 auto;
    }
    
    .about-talent-growth-cards {
        display: none;
    }

    .about-talent-slider{
        display: block;
        margin-top: 20px;
        overflow: hidden;
    }

    /* ------------ Talent Growth Cards ------------ */
    
    .talent-cards-heading{
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #26344E; 
        margin-bottom: 40px;
    }

    .talent-growth-cards {
        padding-top: 30px;
        padding-bottom: 0px;
    }
    
    .talent-cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }

    /* ----------Talent Trainees Section ----------- */
    
    
    .talent-growth-trainees-section {
        background: #FFFFFF;
        padding: 30px 0;
        overflow: hidden;
    }
    
    .talent-trainees-heading {
        max-width: 263px;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
    }
    
    .talent-growth-trainees {
        flex-direction: column;
    }
    
    
    .talent-growth-tranees-bottom {
        padding-bottom: 30px;
        position: relative;
    }
    
    .talent-growth-tranees-bottom .swiper-button-next {
        left: 240px;
        top: 430px;
        position: absolute;
        
    }
    
    .talent-growth-tranees-bottom .swiper-button-prev {
        left: 185px;
        top: 430px;;
    }
    
    .talent-growth-tranees-bottom .swiper {
        position: static;
        width: 426px;
        margin: 0px;
    }
    






}


.careers-hero {
  max-width: 575px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 60px;
}

.carrers-apply-now {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  text-align: center;
  display: block;
  max-width: 385px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.careers-hero-heading {
  font-family: "League Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 67px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #26344e;
}

/* -------- Job Opening Section ---------- */

.careers-current-openings {
  max-width: 1250px;
  margin: 0 auto;
}

.careers-current-openings-head {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #26344e;
  margin-bottom: 30px;
}

.careers-jobs-div {
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 40px;
}

.career-jobs-date-deadline {
  display: none;
}

.careers-jobs-top {
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 15px;
}

.careers-jobs-top-left {
  display: flex;
  gap: 79px;
}

.job-titles {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #565353;
}

.icon-day-time {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-date-icon-div {
  background: #ffffff;
  border-radius: 5px;
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-job-details {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9d9b9b;
}

.careers-jobs-top-right {
  display: flex;
  gap: 42px;
}

.careers-jobs-heading {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #26344e;
  margin-bottom: 15px;
}
.height-container {
  overflow: hidden;
  max-height: 0px;
  transition-duration: 0.5s;
}

.careers-jobs-para {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 15px;
}

ul.career-job-list {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
}

ul.career-requirement-list {
  margin-left: 30px;
  margin-bottom: 30px;
}

li.requirements-lists {
  list-style: disc;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.careers-ideometrix {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #26344e;
}

.career-job-time-city {
  display: none;
}

.careers-jobs-bottom-btns {
  display: flex;
  align-items: center;
  gap: 25px;
}

.careers-apply-now-btn {
  background: #26344e;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  padding: 12px 15px;
}
.careers-apply-btn-disabled {
  cursor: not-allowed;
  background: #505e7a;
  border-radius: 5px;
  cursor: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  cursor: not-allowed;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  padding: 12px 15px;
}
/* .careers-apply-now-btn:disabled {
  cursor: not-allowed;
  background: #505e7a;
} */

.careers-learn-more {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
}

/* -----------Media Queries for Mobile ----------- */

@media (max-width: 426px) {
  .careers-hero {
    max-width: 272px;
    padding-top: 81px;
    padding-bottom: 45px;
  }

  .carrers-apply-now {
    font-size: 12px;
    line-height: 16px;
    max-width: 213px;
  }

  .careers-hero-heading {
    font-size: 34px;
    line-height: 33px;
    letter-spacing: 0.03em;
  }

  /* -------- Job Opening Section ---------- */

  .careers-current-openings {
    max-width: 345px;
    margin-bottom: 30px;
  }

  .careers-current-openings-head {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .careers-jobs-div {
    background: transparent;
    border: none;
    border-radius: 0px;
    padding: 0px;
  }

  .career-jobs-date-deadline {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 13px;
  }

  .careers-jobs-top {
    display: none;
  }

  .career-job-posted-day,
  .career-job-post-deadline {
    font-family: "Cabin";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #565353;
  }

  .icon-day-time {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .time-date-icon-div {
    background: #ffffff;
    border-radius: 5px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .time-date-icon-div img {
    width: 16px;
  }

  .career-job-details {
    font-size: 12px;
    line-height: 15px;
  }

  .careers-jobs-heading {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  /* .careers-jobs-description {
    overflow: hidden;
    max-height: 63px;
  } */

  .careers-jobs-para {
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    margin-bottom: 15px;
  }

  ul.career-job-list {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  ul.career-requirement-list {
    margin-left: 30px;
    margin-bottom: 30px;
  }

  li.requirements-lists {
    font-size: 12px;
    line-height: 22px;
  }

  .careers-ideometrix {
    font-size: 12px;
    line-height: 24px;
  }

  .career-job-time-city {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .careers-apply-now-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 8px 29px;
  }

  .careers-learn-more {
    font-size: 12px;
    line-height: 24px;
  }
}

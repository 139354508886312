.talent-growth-card {
    position: relative;
    max-width: 255px;
}

.talent-growth-img {
    position: relative;

}

.talent-growth-card-top {
    position: absolute;
    top: -20px;
    left: 77px;
    right: 0;
    max-width: 50.5px;
    max-height: 50.5px;
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(91, 132, 193, 0.1);
    padding: 13.5px;
    border-radius: 50%;
}

.talent-growth-card-number {
    position: absolute;
    top: 55px;
    left: 0px;
    right: 0;
    display: flex;
    justify-content: center;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
}

.talent-growth-card-head {
    position: absolute;
    top: 135px;
    left: 45px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #696969;
    max-width: 118px;
}

/* -------- Media Queries for Mobile ---------- */
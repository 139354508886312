.d-flex {
  display: flex;
}

.gap {
  gap: 11px;
}
ol.modal-courses-names li {
  /* list-style: decimal; */
  list-style: none;
  color: #2c3643;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.5px;
  max-width: 425px;
  /* margin-left: 20px; */
  margin-left: 0px;
}

.courses-modal {
  background: rgba(241, 241, 241, 0.63);
  position: fixed;
  /* top: 0; */
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900;
  margin: 0 auto;
}

.courses-modal-inner {
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 872px;
  height: 540px;
  margin-top: 40px;
}

.modal-inner-top {
  display: flex;
  justify-content: space-between;
  padding: 28px 35px;
}

.cross-btn:hover {
  cursor: pointer;
}

.modal-main {
  display: flex;
  position: relative;
}

.courses-modal-left {
  display: flex;
  flex-direction: column;
  padding-left: 33px;
  position: relative;
  /* height: 422px; */
  height: 422px;
}

.orange {
  color: #f37020;
}

.primary-blue{
    color: #2C3643;
}

.courses-modal-head {
  font-family: "League Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  letter-spacing: 0.6px;
  color: #2C3643;
  margin-bottom: 19px;
  max-width: 465px;
  margin-top: 20px;
}

.apply-date {
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  bottom: 87px;
}

.apply-before {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #666666;
}

.last-date-apply {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #666666;
}

.course-modal-buttons {
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: 25px;
}

.course-modal-registerNow-btn {
  border: none;
  width: 124px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #980669 0%, #da0669 100%);
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}

.course-modal-learnMore-btn {
  border: 1px solid #da0669;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #da0669;
  width: 124px;
  height: 42px;
  background: transparent;
  cursor: pointer;
}

.courses-modal-right {
  height: 417px;
  position: absolute;
  top: 20px;
  right: 30px;
}

.courses-modal-right img {
  width: 100%;
}

/* -------- Media Queries for Mobile ---------- */

@media (max-width: 431px) {
  .courses-modal {
    background: rgba(241, 241, 241, 0.63);
    position: fixed;
    /* top: 0; */
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
    margin: 0 auto;
  }

  .courses-modal-inner {
    margin-top: 20px;
    width: 345px;
    height: 514px;
  }

  .modal-inner-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 23px;
    padding-bottom: 42px;
  }

  .modal-main {
    display: flex;
    position: relative;
  }

  .courses-modal-left {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
    padding-right: 21px;
    position: relative;
    height: 410px;
  }

  .courses-modal-head {
    font-size: 32px;
    line-height: 43px;
    margin-bottom: 10px;
    letter-spacing: 0.32px;
    max-width: 243px;
  }

  .courses-modal-right {
    display: none;
  }

  .course-modal-buttons {
    margin-top: 20px;
  }
}

.digihunar-hero-section {
    margin-top: 84px;
    background: url('./DigiHunarImages/DigiHeroBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.digihunar-hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 90px 120px;
}

.digihunar-hero-left {
    position: relative;
    max-width: 522px;
}

.digihunar-logo {
    margin-bottom: 10px;
}

.digihunar-hero-heading {
    max-width: 402px;
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 67px;
    letter-spacing: 0.01em;
    color: #26344E;
    margin-bottom: 30px;
}

.digi-hero-left-card {
    /* max-width: 120px; */
    position: absolute;
    right: 200px;
    top: 135px;
    background: #FFFFFF;
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    padding: 10.52px 13px;
}

.digi-hero-left-card-imgs {
    /* transform: translateX(-5px); */
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
}



.digihunar-hero-para {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #666666;
    margin-bottom: 58px;
}

.visit-digihunar {
    border: none;
    background: #347EC1;
    border-radius: 8px;
    padding: 12px 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}

.digihunar-hero-right {
    position: relative;
}

.digi-hero-right-card {
    position: absolute;
    right: -10px;
    bottom: 30px;
}

.digihunar-slider-section {
    background: #F9F9F9;
}

.digihunar-slider-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: #2B2B2B;
    text-align: center;
    padding-top: 30px;
}

.digihunar-slider .swiper-wrapper {
    display: flex;
    align-items: center;
}

.digihunar-slider .mySwiper {
    margin: 0px auto;
    padding: 66px 0;
}

.digihunar-slider .swiper {
    position: static;
    max-width: 1258px;
}

.digi-courses {
    padding: 50px 50px;
}

.digi-courses-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
}

.digi-courses-para {
    max-width: 998px;
    margin: 0 auto;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #666666;
}

.digi-courses-cards {
    justify-content: center;
    position: relative;
    padding-bottom: 10px;
}

.digi-courses-cards .mySwiper {
    display: flex;
    justify-content: center;
}

.digi-courses-cards .swiper {
    position: static;
    max-width: 1245px;
    height: 590px;
    margin: 0 auto;
}

.swiper .swiper-pagination {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

}

.swiper-pagination-bullet {
    background: rgba(49, 160, 250, 0.5);
    width: 15px;
    height: 15px;
    opacity: 1;
}

.swiper-pagination-bullet-active{
    background: #1560AE;
}

.digi-courses .swiper-pagination-bullet-active::after,  .digihunar-testimonials .swiper-pagination-bullet-active::after{
    content: '';
    position: relative;
    top: -3.3px;
    left: -4.4px;
    padding: 0px 11px;
    border: 2px solid rgba(49, 160, 250, 0.5);
    border-radius: 50%;
}


.digi-testimonials-section {
    background: #F9F9F9;
}

.digihunar-testimonials {
    padding: 50px;
}

.digi-testimonial-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
}

.digi-testimonial-para {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #505050;
    margin-bottom: 40px;
}
.digi-testimonial-slider{
    position: relative;
    padding-bottom: 10px;
}

.digi-testimonial-slider .mySwiper{
    display: flex;
    gap: 64px;
    justify-content: center;
    position: relative;
    padding-bottom: 50px;
}


.digi-testimonial-slider .swiper {
    position: static;
    max-width: 1250px;
    margin: 0 auto;
    overflow: hidden;
}



/* -------- Media Queries for mobile ---------- */

@media(max-width:426px){
    .digihunar-hero-section {
        margin-top: 54px;
        background: url('./DigiHunarImages/DigiHeroBg.png');
        background-repeat: no-repeat;
        /* background-size: cover; */
        /* background-position: center; */
    }
    
    .digihunar-hero {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        height: 510px;
    }
    
    .digihunar-hero-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        max-width: 283px;
        margin: 0 auto;
        height: 100%;
    }
    
    .digihunar-logo {
        width: 66.28px;
        height: 30px;
        margin-bottom: 10px;
    }
    
    .digihunar-hero-heading {
        max-width: 246px;
        font-size: 34px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 0.03em;
        margin-bottom: 10px;
    }
    
    .digi-hero-left-card {
        display: none;
    }
    
    .digihunar-hero-para {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #727272;
        text-align: center;
        /* margin-bottom: 58px; */
        margin-bottom: 20px;
    }

    .digihunar-hero-left a{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        cursor: pointer;
        z-index: 100;
    }
    
    .visit-digihunar {
        border-radius: 5px;
        padding: 11px 20px;
        font-size: 11px;
        line-height: 13px;
        position: absolute;
        bottom: -10px;
    }
    
    .digihunar-hero-right {
        position: absolute;
        top: 120px;
        bottom: 0;
        left: 0px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .digihunar-hero-right-img{
        height: 220px;
        width: 220px;
    }
    
    .digi-hero-right-card {
        position: absolute;
        right: 95px;
        bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* ------------ DigiHunar first Slider Section ---------  */
    
    .digihunar-slider-heading {
        font-size: 16px;
        line-height: 19px;
        padding-top: 15px;
    }

    .mbl-responsive{
        width: 80px;
    }
    /* .mbl-responsive-87{
        width: 87px;
    }
    .mbl-responsive-78{
        width: 87px;
    }
    .mbl-responsive-77{
        width: 77px;
    }
    .mbl-responsive-63{
        width: 63px;
    }
    .mbl-responsive-98{
        width: 98px;
    } */
    
    .digihunar-slider .swiper-wrapper {
        display: flex;
        align-items: center;
    }
    
    .digihunar-slider .mySwiper {
        margin: 0px auto;
        padding: 10px 0;
    }
    
    .digihunar-slider .swiper {
        position: static;
        max-width: 426px;
        padding-bottom: 15px;
    }
    .digihunar-slider .swiper-slide{
        margin: 10px;
    }

    /* ----------- Digi Courses Section Media Queries ----------- */
    
    .digi-courses {
        padding: 30px 1px;
    }
    
    .digi-courses-heading {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    
    .digi-courses-para {
        max-width: 322px;
        margin: 0 auto;
        font-size: 12px;
        line-height: 18px;
    }
    
    .digi-courses-cards {
        justify-content: center;
        position: relative;
        padding-bottom: 0px;
        overflow: hidden;
        margin-top: 0px;
    }
    
    .digi-courses-cards .mySwiper {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        display: flex;
        gap: 15px;
        overflow: hidden;
    }
    
    .digi-courses-cards .swiper {
        position: static;
        height: 420px;
        width: 965px;
        overflow: hidden;
        margin: 0px;
    }
    
    .swiper .swiper-pagination {
        display: none;
    
    }


/* --------- Digi Testimonial Slider Section ---------- */
    
    
    .digihunar-testimonials {
        padding: 30px 5px;
        overflow: hidden;
    }
    
    .digi-testimonial-heading {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    
    .digi-testimonial-para {
        line-height: 18px;;
        letter-spacing: 0.01em;
        margin-bottom: 30px;
    }
    .digi-testimonial-slider{
        position: relative;
        padding-bottom: 0px;
    }
    
    .digi-testimonial-slider .mySwiper{
        display: flex;
        gap: 0px;
        height: 235px;
        justify-content: center;
        position: relative;
        /* padding-bottom: 50px; */
        padding-bottom: 0px;
        overflow: hidden;
    }
    
    
    .digi-testimonial-slider .swiper {
        position: static;
        width: 640px;
        margin: 0;
        overflow: hidden;
    }
    
}

@media(max-width:380px){
    .digi-hero-right-card {
        position: absolute;
        right: 65px;
        bottom: 195px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
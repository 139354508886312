.form-submit-container {
    height: 60vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 90px;
}

.form-submit-container h1 {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 67px;
    letter-spacing: 0.01em;
    color: #2C3643;
    text-align: center;
    margin-bottom: 0px;
}

.form-submit-container button {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    background: #26344E;
    border-radius: 8px;
    padding: 13px 41px;
    /* max-width: 135px; */
    cursor: pointer;
}

@media(max-width:426px) {
    .form-submit-container {
        height: 60vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        margin-top: 61px;
        text-align: center;
    }

    .form-submit-container h1 {
        font-size: 34px;
        line-height: 33px;
        letter-spacing: 0.03em;
        max-width: 204px;
    }

    .form-submit-container button {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #FFFFFF;
        background: #26344E;
        border-radius: 8px;
        padding: 9px 38px;
        /* max-width: 135px; */
        cursor: pointer;
    }
}
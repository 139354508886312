.stepup-hero-card {
    width: 215px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

}

.stepup-hero-card h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #DA0669;;
}

.stepup-hero-card p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #696969;
    margin-top: 8px;
}

.stepup-hero-card-top {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 431px) {
    .stepup-hero-card {
        width: 154px;
        height: 97px;
    }

    .stepup-hero-card h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color:  #DA0669;
    }

    .stepup-hero-card p {
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
    }

}
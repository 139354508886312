.job-application-container {
  padding: 20px;
  margin-top: 5%;
  width: 100%;
  /* height: 200vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 38%; */
}
.job-application-container h2 {
  text-align: center;
  color: #26344e;
  padding-bottom: 2%;
}
.job-application-form {
  display: flex;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  justify-content: center;
  width: 50%;
  gap: 16px;
  flex-direction: column;
}

.input-field {
  /* flex: 1 1 calc(33.33% - 16px); */
  display: flex;
  flex-direction: column;
  /* min-width: 150px; */
}

.input-field label {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}
.input-field select {
  padding: 12px 15px;
  /* width: 70%; */
  border: 0.5px solid #b9b9b9;
  border-radius: 5px;
  font-family: "Cabin";
  font-style: normal;
  outline: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #989898;
}

.input-field input {
  padding: 8px 15px;
  /* width: 70%; */
  border: 0.5px solid #b9b9b9;
  border-radius: 5px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #989898;
}
/* .input-field .dropDown {
  padding: 8px 15px !important;
  border: 0.5px solid #b9b9b9;
  border-radius: 5px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #989898;
} */

.job-application-form label span {
  color: #f1ab27;
}
.input-field span {
  color: #f1ab27;
}

.custom-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px dashed #ccc;
  padding: 12px;
  border-radius: 4px;
}

.job-application-form .submit-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  background: #26344e;
  border-radius: 8px;
  padding: 12px 50px;
  margin-top: 20px;
  /* width: 100%;/ */
  /* max-width: 400px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-field input:focus {
  outline: none;
  border-color: #f1ab27;
}
.experience input:focus {
  outline: none;
  border-color: #f1ab27;
}
.File-picker {
  border: 1px solid rgb(206, 212, 218) !important;
  width: 100%;
  height: 220px !important;
  border-radius: 5px !important;
}
.File-picker .files-ui-footer-border-rd-top-bg-color-default {
  border-top: 1px solid rgb(206, 212, 218) !important;
  font-size: 14px;
}
.File-picker .files-ui-dropzone-children-container label {
  font-size: 16px;
}
.File-picker span {
  font-size: 12px;
}
.File-picker svg {
  width: 20px;
}
.File-picker .files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container {
  height: 100px;
  width: 100px;
}


.applicant-form-error{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 10px;
  color: #FF6669;
  background-color: #FFCDD2;
  
}
.applicatant-form-success{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 10px;
  color: #347928;
  background-color: #C0EBA6
  
}
.applicant-form-error h1{
  font-size: 18px;
  color: #FF6669;
} 
.applicatant-form-success h1{
  font-size: 18px;
  color: #347928;
} 
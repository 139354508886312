.aboutUs-section {
    background: url('./About-Imagess/aboutBG.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.aboutUs {
    margin-top: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    margin-bottom: 40px;
}

.aboutUs-content {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
}

.aboutUs-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 40px;
}

.aboutUs-paragraphs {

    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #666666;
    margin-bottom: 45px;
}

.aboutUs-paragraphs-2 {
    display: none;
}

.ourTeam-section {
    background: #FBFBFB;
    padding: 50px 0;

}

.ourTeam-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: #26344E;
    text-align: center;
    margin-bottom: 40px;
}

.ourTeam-cards {
    max-width: 1240px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 0 auto;

}

.ourEmployee-cards{
    max-width: 1240px;
    display: flex;
    gap: 32px;
    /* justify-content: center; */
    margin: 30px auto;
    flex-wrap: wrap;

}

.ourTeam-card-btn{
    display: none;
}

/* ----------- Media Queries for Mobile ---------- */

@media(max-width:426px) {

    .aboutUs-section {
        background: url('./About-Imagess/aboutBG2.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .aboutUs {
        margin-top: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 33px;
        margin-bottom: 33px;
    }

    .aboutUs img {
        width: 335px;
        height: 199px;
    }

    .aboutUs-content {
        max-width: 335px;
    }

    .aboutUs-heading {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }

    .aboutUs-paragraphs {
        display: none;
    }

    .aboutUs-paragraphs-2 {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #727272;
        display: block;
        margin-bottom: 12px;
    }



    .aboutUs-paragraphs a.primary-color {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.3px;
    }

    /* ----------- Our Team Section Mobile ------------ */

    .ourTeam-section {
        background: #FBFBFB;
        padding: 30px 0;

    }

    .ourTeam-heading {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 43px;
        color: #26344E;
        text-align: center;
        margin-bottom: 40px;
    }

    .ourTeam-cards {
        max-width: 1240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 32px;
        flex-wrap: wrap;
        margin: 0 auto;
    }

    .ourTeam-card-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background: #FFFFFF;
        border: 1px solid rgba(241, 171, 39, 0.5);
        border-radius: 5px;
        padding: 7px 15px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        width: 110px;
        margin: 0 auto;
    }

    .ourTeam-card-btn:hover{
        color: #F1AB27;
    }

    .ourTeam-card-btn .OurTeam-btn{
        font-size: 12px;
        font-weight: lighter;
        color: #000000;
    }

    .ourTeam-card-btn:hover .OurTeam-btn{
        color: #F1AB27;
    }

    .ourTeam-cards{
        
        overflow: hidden;
        max-width: 345px;
        transition-duration: 1s;
    }

    .ourEmployee-cards{
        max-height: 1043px;
        max-width: 345px;
        overflow: hidden;
        gap: 9px;
    }

}
.error404-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    gap: 60px;
    margin-bottom: 120px;
}

.error-404 {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #26344E;
}

@media(max-width:426px) {
    .error404-msg {
        margin-top: 100px;
        gap: 60px;
        margin-bottom: 80px;
    }

    .error404-msg img{
        width: 130px;
        height: 92px;
    }
    
    .error-404 {
        font-size: 14px;
        line-height: 17px;
    }
}
.trainee-sayings {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 62px;
}

.trainee-details-sayings {
    max-width: 550px;
}

.trainee-saying-left img {
    width: 231.64px;
    height: 222.89px;
}

.talent-trainee-para {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #666666;
    margin-bottom: 40px;
    max-width: 549px;
}


.talent-trainee-name {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #26344E;
    display: flex;
    align-items: center;
    gap: 7.5px;
    margin-bottom: 10px;
}

.trainee-dash {
    border: 2px solid #26344E;
    width: 13.5px;
    border-radius: 50px;
}

.talent-trainee-position {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #57605E;
    margin-left: 21px;
}

/* ---------- Media Queries for Mobile ---------- */
@media(max-width:426px){
    .trainee-sayings {
        flex-direction: column;
        gap: 16px;
    }
    
    .trainee-details-sayings {
        max-width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .trainee-saying-left img {
        width: 190.33px;
        height: 200px;
    }
    
    .talent-trainee-para {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        text-align: center;
        color: #666666;
        margin-bottom: 15px;
        /* max-width: 295px; */
    }
    
    
    .talent-trainee-name {
        margin-bottom: 10px;
    }
    
    .trainee-dash {
        border: 1px solid #26344E;
        width: 13.5px;
        border-radius: 50px;
    }
}

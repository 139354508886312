.primary-color {
    color: #F1AB27;
}

.imx-section {
    background-color: #FFFFFF;
    margin-top: 20px;
    overflow: hidden;
}

.features-cards-slider {
    display: none;
}

.imx {
    margin-top: 90px;
    padding: 80px 110px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;

}

.imx-left {
    max-width: 611px;
}

.imx-left span.gb {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* letter-spacing: 0.3px; */
    color: #2C3643;
}

.imx-left .heading-rating {
    position: relative;
    margin-bottom: 24px;
    margin-top: 10px;
}

.imx-left .heading-rating h1 {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 67px;
    letter-spacing: 0.01em;
    color: #26344E;
}

.heading-rating .rating-details {
    position: absolute;
    right: 70px;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.rating-details .clients-ratings p.happy-client {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #7E7E7E;
    margin-bottom: 6px;

}

.rating-details .clients-ratings .clients-ratings-bottom {
    display: flex;
    gap: 5px;
}

.clients-ratings-bottom span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
}

.imx-left p.imx-left-para {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #777D8A;
    /* changes */
    margin-top: 15px;
    margin-bottom: 28px;
}

.imx-btn-pricing {
    display: flex;
    align-items: center;
    gap: 15px;

}



.imx-left button.imx-contact-btn {
    border: none;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    width: 121px;
    height: 44px;
    background: #26344E;
    border-radius: 5px;
    cursor: pointer;
}

button.imx-learnMore {
    background: #FFFFFF;
    border: 1px solid #26344E;
    border-radius: 4px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #26344E;
    width: 121px;
    height: 44px;
    cursor: pointer;
}

.imx-right {
    max-width: 665px;
    position: absolute;
    right: 0px;
}

.features-section {
    background-color: #F4F5F6;
}

.features {
    padding: 57px 0;
}

.features-span {
    /* font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px; */
    font-family: 'Cabin';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.5px;
    line-height: 50px;
    display: flex;
    justify-content: center;
}

.features-heading {
    font-family: 'DM Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    /* letter-spacing: -0.5px; */
    color: #2C3643;
    margin-bottom: 35px;
    text-align: center;
}

.features-cards {
    max-width: 1220px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 70px;
    margin: 0 auto;
    /* border: 1px solid red; */
}


/* ------------ Customer Satisfaction Section -------------- */

.customers-section {
    background: #FFFFFF;
}

.customers {
    padding: 55px 110px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.customers-left {
    max-width: 516px;
}

.customers-left img {
    width: 100%;
}

.customers-right {
    max-width: 542px;
}

.customers-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    letter-spacing: -0.5px;
    color: #2C3643;
    margin-bottom: 20px;
}

.customers-para {
    max-width: 493px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #8B919E;
    margin-bottom: 20px;
}

.customers-values {
    max-width: 437px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.customers-values div {
    display: flex;
    /* flex: 1 1 223px; */
    align-items: center;
    gap: 10px;
    width: 180px;
}

.customers-values div span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 45px;
    color: #02073E;
}

/* ------------ products Section --------------- */

.products-section {
    background: #FAFAFA;
}

.products {
    padding: 65px 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.products-span {
    display: flex;
    justify-content: center;
    font-family: 'Cabin';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.5px;
    line-height: 50px;
    text-align: center;
}

.products-heading {
    text-align: center;
    font-family: 'DM Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 25px;
    color: #2C3643;
    margin-bottom: 70px;
}

.products-cards {
    max-width: 1078px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 46px;
}

/* ----------- Recent Work Section --------------- */

.recent-work-section {
    background: #FFFFFF;
    /* transform: matrix(1, 0, 0, -1, 0, 0); */
}

.recent-work {
    padding: 50px 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.recent-work-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #2C3643;
    margin-bottom: 10px;
}

.recent-work-para {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #0F2137;
    /* max-width: 623px; */
    text-align: center;
    margin-bottom: 25px;
}

.recent-work-pages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 33px;
    margin-bottom: 31px;
}

.recent-work-pages .recent-work-span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #0F2137;
    cursor: pointer;
    transition-duration: .3s;

}
.recent-work-pages .active-category-work{
    color: #F1AB27;
}
.recent-work-span:hover {
    color: #F1AB27;
}

.recent-work-pages .primary-color {
    color: #F1AB27;
}

.recent-work-images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 38px;
}

/* ------------ Testimonials Section ------------ */

.testimonials-section {
    background: #FAFAFA;
    /* overflow: hidden; */
}

.testimonials {
    padding-top: 59px;
    padding-bottom: 0px;
    background: url('../Home/Home-images/testimonials-bg.png');
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

}

.testimonials {
    position: relative;
}

.testimonials-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #2C3643;
}

.testimonials-para {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #0F2137;
    max-width: 751px;
    margin-bottom: 271px;
}

.home-testimonial-slider {
    position: relative;
    height: 315px;
    top: -110px;
    /* border: 1px solid red; */
}

.home-testimonial-slider .swiper {
    position: absolute;
    /* border: 1px solid red; */
}

.home-testimonial-slider .swiper-slide {
    margin-top: 90px;
}

.compaines-slider .swiper-button-next,
.compaines-slider .swiper-button-prev {
    border: 1px solid #F1AB27;
    border-radius: 5px;
    transform: rotate(135deg);
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;

}

.home-testimonial-slider .swiper-button-next {
    right: 10px;
    top: 195px;
}

.home-testimonial-slider .swiper-button-prev {
    left: 10px;
    top: 195px;
}

.home-testimonial-slider .swiper {
    position: static;
    max-width: 1100px;
    /* margin: 0 70px; */
}

.home-testimonial-slider .swiper-pagination {
    position: absolute;
    top: 385px;
    /* bottom: -20px; */
}

.home-testimonial-slider .swiper-pagination-bullet {
    background: rgba(241, 171, 39, .5);
    width: 15px;
    height: 15px;
    opacity: 1;
}

.home-testimonial-slider .swiper-pagination-bullet-active {
    background: #F1AB27;
}

.home-testimonial-slider .swiper-pagination-bullet-active::after {
    content: '';
    position: relative;
    top: -3.3px;
    left: -4.4px;
    padding: 0px 11px;
    border: 2px solid #F1AB27;
    border-radius: 50%;
}



/* ----------- Companies Section ----------- */

.companies-section {
    background: #FFFFFF;
}

.companies {
    padding: 0 139px;
    /* padding-bottom: 85px; */
    padding-top: 45px;
}

.companies-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #2C3643;
}

.compaines-slider {
    position: relative;
}

.compaines-slider .swiper-button-next,
.compaines-slider .swiper-button-prev {
    border: 1px solid #F1AB27;
    border-radius: 5px;
    transform: rotate(135deg);
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 127px;

}

.compaines-slider .swiper-button-next {
    right: 0px;
}

.compaines-slider .swiper-button-prev {
    left: 0px;
}

.compaines-slider .swiper {
    position: static;
    max-width: 1100px;
    /* margin: 0 70px; */
}


.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 20px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #F1AB27;
    padding: 20px;
    transform: rotate(-135deg) translateX(3px);
    font-weight: bold;
}

.swiper-button-prev:after {
    transform: rotate(-135deg) translateX(-2px);
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: #F1AB27;

}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
    color: #fff;
}


/* --------------- Media Queries for Mobile ------------- */

@media (max-width:426px) {
    .imx-section {
        height: 550px;
    }

    .imx {
        margin-top: 50px;
        padding: 25px 8px;
        position: relative;
        display: block;
        /* align-items: center;
        justify-content: space-between;
        gap: 50px; */

    }

    .imx-left {
        max-width: 426px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        /* display: none; */
    }

    .imx-left span.gb {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #2C3643;
    }

    .imx-left .heading-rating {
        position: relative;
        margin-bottom: 24px;
        margin-top: 10px;
    }

    .imx-left .heading-rating h1 {
        max-width: 358px;
        font-family: 'League Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #26344E;
    }

    .heading-rating .rating-details {
        display: none;
    }

    .imx-left p.imx-left-para {
        max-width: 298px;
        margin: 0 auto;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #777D8A;
        margin-bottom: 30px;
        position: relative;
        top: 190px;
    }

    

    .imx-btn-pricing {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        top: 190px;
    }

    .imx-btn-pricing .imx-pricing {
        display: none;
        align-items: center;
        gap: 10px;
    }

    .imx-pricing span {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.3px;
    }

    .imx-pricing .imx-pricing-icon {
        font-size: 14px;
        transform: translateY(1px);
    }

    .imx-left button.imx-contact-btn {
        border: none;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        padding: 7px 20px;
        background: #26344E;
        border-radius: 4px;
        cursor: pointer;
    }

    .imx-right {
        max-width: 297px;
        position: absolute;
        right: 0px;
        left: 0px;
        bottom: 0;
        top: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .imx-right img {
        width: 100%;
    }

    .features-section {
        background-color: #F4F5F6;
    }

    .features {
        padding-top: 30px;
        padding-bottom: 30px;
        max-width: 426px;
    }

    .features-span {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        /* text-align: center; */
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .features-heading {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #2C3643;
        margin-bottom: 30px;
        text-align: center;
    }

    .features-cards {
        max-width: 426px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 70px;
        margin: 0 auto;
        display: none;
    }

    .features-cards-slider {
        max-width: 426px;
        overflow: hidden;
        display: block;
    }

    .features-cards-slider .swiper-pagination-bullet {
        display: none;
    }

    .features-cards-slider .mySwiper {
        display: flex;
        gap: 15px;
    }

    .features-cards-slider .swiper {
        width: 550px;
        overflow: hidden;
        margin: 0px;
    }

    /* ------------- Customers Section Media Queries for Mobile ------------- */

    .customers {
        padding: 30px 20px;
        display: block;
        height: 530px;
        position: relative;
    }

    .customers-left {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 150px;
        left: 0;
        right: 0;
    }

    .customers-left img {
        max-width: 275px;
    }

    .customers-heading {
        max-width: 299px;
        margin: 0 auto;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #2C3643;
        text-align: center;
        margin-bottom: 10px;
    }

    .customers-para {
        max-width: 335px;
        margin: 0 auto;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #8B919E;
        margin-bottom: 20px;
    }

    .customers-values {
        max-width: 326px;
        margin: 0 auto;
        display: flex;
        gap: 20px;
        left: 0;
        right: 0;
        flex-wrap: wrap;
        position: absolute;
        top: 400px;
    }

    .customers-values div {
        display: flex;
        flex: 1 1 150px;
        align-items: center;
        gap: 10px;
        max-width: 223px;
    }

    .customers-values div i {
        width: 23px;
        height: 23px;
    }

    .customers-values div span {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 35px;
        color: #02073E;
        max-width: 223px;
    }

    /* ---------- Products Section Media Queries for Mobile ------------ */



    .products {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .products-span {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
    }

    .products-heading {
        width: 180px;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #2C3643;
        margin-bottom: 30px;
    }

    .products-cards {
        max-width: 1078px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }

    /* ----------- Recent Work Section Media Queries for Mobile ----------- */

    .recent-work {
        padding: 30px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .recent-work-heading {
        font-size: 16px;
        line-height: 19px;
        color: #2C3643;
    }

    .recent-work-para {
        font-size: 12px;
        line-height: 18px;
        color: #727272;
        ;
        max-width: 345px;
        margin-bottom: 20px;
    }

    .recent-work-pages {
        gap: 15px;
        margin-bottom: 15px;
    }

    .recent-work-pages .recent-work-span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #727272;
    }

    .recent-work-images {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 38px;
    }

    .dsply-none {
        display: none;
    }

    /* ---------- Testimonial Section Media Queries for Mobile ----------- */

    .testimonials {
        padding: 30px 15px;
        background: url('../Home/Home-images/testmonial-bg2.png');
        height: 430px;
        background-position: center;
        background-repeat: no-repeat;
        background-position-y: 120px;
        overflow: hidden;
    }

    .testimonials-heading {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }

    .testimonials-para {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #727272;
        max-width: 345px;
        margin-bottom: 160px;
    }

    .home-testimonial-slider {
        position: relative;
        height: 310px;
        /* top: 0px; */
        bottom: 200px;
    }

    .home-testimonial-slider {
        position: relative;
        height: 315px;
        top: -95px;
    }

    .home-testimonial-slider .swiper {
        overflow: visible;
    }

    .home-testimonial-slider .swiper-pagination {
        display: none;
    }

    .compaines-slider .swiper-button-next,
    .compaines-slider .swiper-button-prev {
        display: none;
    }


    /* -------- Companies Slider Media Queries for Mobile --------- */

    .companies {
        padding: 0 20px;
        /* padding-bottom: 85px; */
        padding-top: 30px;
    }

    .companies-heading {
        font-size: 16px;
        line-height: 19px;
    }

    .compaines-slider {
        position: relative;
    }

    .compaines-slider .swiper-button-next,
    .compaines-slider .swiper-button-prev {
        border: 1px solid #F1AB27;
        border-radius: 5px;
        transform: rotate(135deg);
        width: 17.68px;
        height: 17.68px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: absolute;
        top: 65px;
    }

    .compaines-slider .swiper-button-next {
        right: 0px;
        top: 80px;
    }

    .compaines-slider .swiper-button-prev {
        left: 0px;
        top: 80px;
    }

    .compaines-slider .swiper {
        position: static;
        max-width: 426px;
        margin: 0 20px;
    }
}
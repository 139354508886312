.digi-course-card {
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    padding: 20px;
    transition: background .5s ease, box-shadow .5s ease;
    height: 505px;
    max-width: 397px;
    position: relative;
}

.digi-course-card:hover {
    background: #FFFFFF;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}


.digi-logo-link {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 26px;
    margin-bottom: 10px;
}

.digi-logo {
    max-width: 30px;
}

.digi-web-link {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145.8%;
    color: #697694;
}

.digi-course-title {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: #2B2B2B;
    margin-bottom: 5px;
}

.digi-course-para {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #666666;
    margin-bottom: 10px;
}

.digi-course-bottom {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.digi-course-bottom-left {
    display: flex;
    align-items: center;
    gap: 7px;
}

.rating-reviews {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145.8%;
    letter-spacing: 0.01em;
    color: #666666;
}

.digi-apply-now {
    border: none;
    background: linear-gradient(90deg, rgba(42, 90, 231, 0.8) 0%, rgba(67, 226, 255, 0.8) 100%);
    border-radius: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 7px 12px;
    cursor: pointer;
}

/* --------- Media Queries for Mobile ---------- */
@media(max-width:426px) {
    .digi-course-card {
        border: 0.78px solid #E9E9E9;
        border-radius: 5px;
        padding: 15px;
        transition: background .5s ease, box-shadow .5s ease;
        height: 397px;
        width: 311.31px;
        position: relative;
    }

    .digi-course-img {
        max-width: 279.9px;
        height: 189.77px;
    }

    .digi-course-card:hover {
        box-shadow: 0px 0px 31.3663px 3.92079px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
    }

    .digi-logo-link {
        display: flex;
        align-items: center;
        gap: 11.77px;
        margin-top: 20px;
        margin-bottom: 14px;
    }

    .digi-logo {
        max-width: 19px;
    }

    .digi-web-link {
        font-size: 9.4px;
    }

    .digi-course-title {
        font-size: 16px;
        margin-bottom: 5px;

    }

    .digi-course-para {
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.23px;
        max-width: 297px;
        margin-bottom: 15px;
    }

    .digi-course-bottom {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
    }

    .digi-course-bottom-left {
        display: flex;
        align-items: center;
        gap: 5.4px;
    }

    .digi-course-bottom-left img {
        width: 15.5px;
    }

    .rating-reviews {
        font-size: 10.9782px;
        line-height: 145.8%;
    }

    .digi-apply-now {
        border-radius: 23.5px;
        font-size: 10px;
        line-height: 12px;
        padding: 5px 10px;
    }
}
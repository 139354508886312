.Hotela-section {
    background-image: url('./Hotelaimages/HOTELA.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    padding-top: 84px;

}

.hotela-hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.hotela-hero-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 50px;
}

.hotela-hero-para {
    margin-top: 35px;
    margin-bottom: 73px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #505050;
}

/* ---------------------Responsive-------------------- */

@media (max-width: 426px) {

    .Hotela-section {
        background-image: url('./Hotelaimages/HOTELA.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 314px;
        padding: 0px;

    }

    .hotela-hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }


    .hotela-hero-img {
        padding-top: 70px;
        width: 127px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hotela-hero-para {
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 15px;
    }

}
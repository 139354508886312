.products-card {
    max-width: 516px;
    height: 410px;
    padding: 0px 50px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
    background: #FFFFFF;
    transition: background .5s ease, box-shadow .5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}

.products-card:hover {
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(91, 132, 193, 0.1);
}

.products-card img {
    margin-bottom: 30px;
}

.products-card p.products-para {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #666666;
    margin-bottom: 36px;
    max-width: 416px;
}

.products-card a.products-link {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #26344E;
    position: absolute;
    bottom: 20px;
}

.products-card a:hover {
    color: #3980C2;
}

/* ----------- Media Queries for Mobile ----------- */
@media (max-width: 426px) {
    .products-card {
        width: 315px;
        padding: 25px 27px;
        text-align: center;
        background: #FFFFFF;
        transition: background .5s ease, box-shadow .5s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        height: 300px;
    }

    .products-card img {
        margin-bottom: 10px;
        width: 66px;
        height: 30px;
    }

    .products-card p.products-para {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #666666;
        margin-bottom: 20px;
        text-align: center;
        max-width: 261px;
    }

    .products-card a.products-link {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: px;
        color: #26344E;
    }

    .products-card a:hover {
        color: #3980C2;
    }
}
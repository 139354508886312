.recent-work-card {
    max-width: 354px;
    padding: 10px;
    background: #F8F8F8;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    transition: background .5s ease, box-shadow .5s ease;
}

.recent-work-card-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 50px;
    letter-spacing: -0.5px;
    color: #000000;
}
.recent-work-card-head{
    width: 334px;
    height: 304px;
}
.recent-work-card:hover {
    background: #FFFFFF;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* --------- Media Queries for Mobile ---------- */
@media(max-width:426px){
    .recent-work-card {
        max-width: 315px;
        padding: 9px;
        background: #F8F8F8;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        transition: background .5s ease, box-shadow .5s ease;
    }
    
    .recent-work-card-head {
        max-width: 297px;
    }
    
    .recent-work-card-heading {
        font-size: 13.36px;
        line-height: 45px;
        letter-spacing: -0.445333px;
    }
}


.testimonial-slider1 {
    position: relative;
    max-width: 994px;
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(91, 132, 193, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    max-height: 240px;
    padding-bottom: 14px;
}

.testimonial-slider-para {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #0F2137;
    margin-top: 31px;
    padding-bottom: 50px;
    max-width: 804px;
    margin: 28px;
}

.testimonial-qout {
    position: absolute;
    left: 20px;
    top: -15px;
}
.testimonial-img-outer img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.testimonial-img-outer{
    position: absolute;
    left: 360px;
    top: -90px;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.testimonial-slider-bottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 14px;
}

.testimonial-p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 5px;
    /* position: absolute;
    bottom: 43px; */
}

.testimonial-bottom-p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #727272;
    /* margin-bottom: 14px; */
    /* position: absolute;
    bottom: 14px; */
}

/* ----------- Media Queries for Mobile ---------- */

@media(max-width:426px) {
    .testimonial-slider1 {
        position: relative;
        max-width: 391px;
        max-height: 225px;
        background: #FFFFFF;
        box-shadow: 0px 15px 50px rgba(91, 132, 193, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        padding-top: 20px;
        top: -100px;
    }

    .testimonial-slider-para {
        font-size: 12px;
        line-height: 16px;
        color: #727272;
        max-width: 281px;
        margin: 10px;
        /* position: absolute;
        top: 43px; */
    }

    .testimonial-qout {
        position: absolute;
        left: 15px;
        top: -10px;
        width: 22px;
    }

    .testimonial-img-outer {
        position: absolute;
        left: 130px;
        top: -23px;
        width: 40px;
        height: 40px;
    }
    .testimonial-img{
        width: 100%;
    }

    .testimonial-slider-bottom{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 14px;
    }

    .testimonial-p {
        font-size: 13px;
        line-height: 10px;
        /* position: absolute;
        top: 23px; */
    }

    .testimonial-bottom-p {
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 0px;
        /* position: absolute;
        bottom: 0px; */
    }
}
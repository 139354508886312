.team-card {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 70px;
    background: #FFFFFF;
    /* border: 4px solid #F2F2F2; */
    border: 1px solid #DFDEDE;
    width: 604px;
    padding: 30px;
    box-sizing: border-box;
    transition: box-shadow .5s ease;
}

.team-card:last-child p{
 content: '';
}

.team-card:hover {
    border: none;
    background: #26344E;
    box-shadow: 0px 30px 70px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.team-card:hover .team-member-name{
    color: #FFFFFF;
}
.team-card:hover .team-member-position{
    color: #FFFFFF;
}
.team-card:hover .team-member-detail{
    color: #FFFFFF;
}
.team-card:hover .followat{
    color: #FFFFFF;
}

.team-card img {
    max-width: 146px;
    transform: translateY(5px);
}
.team-member-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.team-member-name {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #26344E;
}

.team-member-position {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #F1AB27;
    margin-bottom: 10px;
}

.team-member-detail {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.3px;
    color: #666666;
    max-width: 373px;
}

.followat {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.3px;
    color: #666666;
}

.seeMore-btn {
    border: none;
    background: transparent;
    cursor: pointer;
    display: none;
}

.member-social-account {
    display: flex;
    align-items: center;
    gap: 22px;
    margin-top: 42px;
}
.teamcard-linkedin-img,.teamcard-upwork-img{
    width: 32px;
    height: 32px;
}
.bod-image{
    width: 130px;
    position: relative;
    border-radius: 50%;
    background-color: orange;
    height: 130px;

}
.bod-image img{
    width: 123px;
    height: 123px;
     position: absolute;
    left: 10px;
    top: 8px;
    border-radius: 50%;
}
/* ---------- Media Queries for Mobile ----------- */

@media(max-width:426px) {
    .team-card {
        gap: 15px;
        /* border: 1px solid #F2F2F2; */
        width: 345px;
        padding: 20px 15px;
    }

    .team-card:hover {
        background: #26344E;
        box-shadow: 0px 30px 70px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .team-card img {
        max-width: 66px;
        transform: translateY(5px);
    }

    .team-member-details {
        gap: 6px;

    }

    .team-member-name {
        font-size: 16px;
        line-height: 19px;
    }

    .team-member-position {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 4px;
    }

    .team-member-detail {
        font-size: 12px;
        line-height: 18px;
        width: 233px;
    }

    .member-social-account {
        gap: 22px;
        margin-top: 13px;
    }

    .followat {
        font-size: 14px;
    }

    .member-social-account  a img{
        width: 18.38px;
    }



    .seeMore-btn {
        border: none;
        background: transparent;
        cursor: pointer;
        display: none;
    }
}
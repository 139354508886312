
.talent-partners-logo-div {
    width: 149px;
    height: 149px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.talent-partners-logo-div img{
    filter: grayscale(100%);
    transition: filter .5s ease;
}

.talent-partners-logo-div img:hover{
    filter: grayscale(0%);
}

.talent-partners-logo-div:hover {
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.talent-partners-logo-div:hover .talent-growth-partner-img2 {
    display: block;
}

.talent-partners-logo-div:hover .talent-growth-partner-img1{
    display: none;
}

/* ----------- Media Queries for Mobile ----------- */

@media(max-width:426px){
    .talent-growth-partner-img{
        width: 55px;
        /* height: 55px; */
    }
    
    .talent-partners-logo-div {
        width: 70px;
        height: 70px;
        padding: 15px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

header {
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    font-family: 'Cabin';
    font-style: normal;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 200;

    /* display: none; */
}

#primary-color {
    color: #F1AB27;
}

.header-inner {
    max-width: 1440px;
    margin: 0 auto;
}

nav {
    padding: 12px 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    font-family: 'Cabin';
    font-style: normal;
}

nav .logo {
    transform: translateY(5px);
    
}

nav .list-btn {
    display: flex;
    align-items: center;
    gap: 50px;
}

nav ul {
    display: flex;
    align-items: center;
    gap: 28px;
}


nav ul .link-drop,
.products-options,
.programs-options {
    display: flex;
    align-items: center;
    gap: 0px;
    position: relative;
}

.drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

nav ul .link-drop li a {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    /* padding: 20px 0; */
}

nav ul .link-drop li {
    padding: 20px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

nav ul .link-drop li a:hover {
    color: #F1AB27;
}

.products-options li {
    cursor: pointer;
}

.products-options:hover:hover .arrow-down {
    color: #F1AB27;
}

.products-options:hover li {
    color: #F1AB27;
}

.programs-options li {
    cursor: pointer;
}

.programs-options:hover .arrow-down {
    color: #F1AB27;
}

.programs-options:hover li {
    color: #F1AB27;
}

.drop:hover .dropdown-arrow{
    height: 13px;
}

.drop:hover .drop-down {
    max-height: 124px;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.06);
    /* transition: max-height .5s ease; */
    overflow: visible;
}

.nav-list li.links-style {
    cursor: pointer;
}


.nav-list li.links-style:hover {
    color: #F1AB27;
}

.active-li {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

nav ul .link-drop li a.active,
nav ul .link-drop {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

nav ul .link-drop .drop-icon {
    font-size: 28px;
    color: #666666;
}

nav .list-btn .btn {
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 12px 15px;
    background: #26344E;
    border-radius: 5px;
    cursor: pointer;
}

.drop-down {
    position: absolute;
    background: #FFFFFF;
    border-radius: 4px;
    top: 55px;
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    left: 0px;
    
}

.dropdown-arrow{
    width: 13px;
    height: 0px;
    position: relative;
    top: -6px;
    left: 15px;
    z-index: 1;
    background: #FFFFFF;
    transform: rotate(45deg);
    margin-bottom: 5px;
    
}

.dropdown-links{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.drop-down .drop-link:first-child {
    padding-right: 0px;
    width: 146px;
}

.drop-down .drop-link:last-child {
    margin-bottom: 25px;
    padding-left: 20px;
    width: 152px;
}

.drop-down a {
    width: 132px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    padding-left: 20px;
}

.drop-down a.primary-style {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.drop-down a:hover {
    color: #F1AB27;
}

/* Navbar-2 Styling */

.navbar-2-div {
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    font-family: 'DM Sans';
    font-style: normal;
    top: 0;
    z-index: 1000;
    position: fixed;
    transition: max-height .5s ease;
    display: none;
    overflow: hidden;
}

.res-drop {
    overflow: hidden;
}

.zero {
    max-height: 0px;
}

.height {
    max-height: 300px;
}

.navbar-2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 15px;
    height: 50px;
}

.close-btn {
    position: absolute;
    right: 15px;
}

.small-nac {
    width: 80px;
    height: 14.58px;
}

.hamburger {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
}

.ham-div-2 {
    width: 9.47px;
    border: 1.5px solid #F1AB27;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.ham-div-1,
.ham-div-3 {
    width: 18px;
    border: 1.5px solid #F1AB27;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.ham-close {
    width: 16px;
    height: 16px;
    border: 1.5px solid #F1AB27;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px;
}



.ham-right-cross {
    width: 9px;
    border: 1.5px solid #F1AB27;
    rotate: 45deg;
    transform: translate(.8px, 1px);

}

.ham-left-cross {
    width: 9px;
    border: 1.5px solid #F1AB27;
    rotate: -45deg;
    transform: translate(1.4px, -1px);
}

.nav-list {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    background: #FFFFFF;
    z-index: 1;
    overflow: hidden;
    max-height: 0;
    transition: max-height .7s ease;
    margin-bottom: 10px;

}


.nav-list-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666666;
}

.nav-list-icon .nav-2-drop-icon {
    width: 30px;
    font-size: 14px;
    color: #666666;
}

.nav-list li a.normal-style,
.nav-list li.links-style {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #666666;
    cursor: pointer;
}



.nav-list li.extra-margin {
    margin-bottom: 10px;
}

.product-dropdown {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 0;
    overflow: hidden;
    transition-duration: .5s;
}

.product-dropdown a {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgb(166, 163, 163);
    padding-left: 15px;
}

a.a-primary-style {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: .5px;
}

.nav-list li a,
.nav-list li.li-primary-style {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #666666;
    cursor: pointer;
}


/* ------ Media Queries ---------- */

@media (max-width:1055px) {
    .navbar-2-div {
        display: block;
        width: 100%;
        overflow: hidden;
        z-index: 200;
        /* border: 2px solid red; */
    }

    header {
        display: none;
    }
}
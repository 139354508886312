.employee-card {
    max-width: 286px;
    background: #FFFFFF;
    border: 1px solid #DFDEDE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: relative; */
    padding: 25px 48px;
}

.employee-card:hover {
    border: 1px solid #DFDEDE;;
    background: #26344E;
    box-shadow: 0px 30px 70px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.employee-card:hover .employee-name {
    color: #FFFFFF;
}
.employee-card:hover .employee-position {
    color: #FFFFFF;
}
.employee-card:hover .followat {
    color: #FFFFFF;
}

.employee-card .employee-img{
    height: 189px;
    width: 189px;
    border-radius: 50%;
}

.employee-name {
    margin-top: 10px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    /* height: 60px; */
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    color: #26344E;
}

.employee-position {
    margin-top: 10px;
    font-family: 'Cabin';
    font-style: normal;
    /* height: 50px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #9E9E9E;
}

.employee-social-account{
    margin-top: 12px;
    margin-bottom: 5px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
}
/* .bod-image{
    width: 130px;
    position: absolute;
    left: 5px;
    background-color: orange;
    height: 130px;

}
.bod-image .employee-img{
    border-radius: 50%;
} */
.linkedin-img,.upwork-img{
    width: 32px;
    height: 32px;
}


/* ---------- Media  queries for Mobile ---------- */

@media(max-width:426px){
    .employee-card {
        width: 168px;
        padding: 10px 0px;
    }
    
    .employee-card:hover {
        border-radius: 5px;
    }

    .employee-img{
        width: 89px;
    }

    .employee-card .employee-img{
        height: 89px;
        width: 89px;
    }
    
    .employee-name {
        margin-top: 5px;
        font-size: 16px;
        line-height: 19px;
    }
    
    .employee-position {
        margin-top: 10px;
        font-size: 14px;
        line-height: 17px;
    }
    
    .employee-social-account{
        margin-top: 12px;
        margin-bottom: 0px;
        gap: 19.5px;
    }

    .employee-card .followat{
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.3px;
    }

    .linkedin-img,.upwork-img{
        width: 21px;
        height: 21px;
        transform: translateY(2px);
    }
}
.secondary-color {
  background: #da0669;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

/* -------------Hero-Section------------------ */

.stepup-hero-section {
  /* background-image: url('./Stepupimages/hero-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
  /* background: #FFF; */
  margin-top: 84px;
  padding-top: 21px;
  /* border: 1px solid red; */
}

.stepup {
  background-image: url("./Stepupimages/hero-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 1360px;
  margin: 0 auto;
  padding: 87px 80px;
  /* padding-top: 100px; */
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.15);
  border-radius: 25px;

  /* border: 1px solid red; */
}

/* -------------Hero-Left------------------------ */

.stepup-left {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.stepup-hero-icon {
  width: 106px;
  height: 39px;
}

.stepup-hero-heading {
  max-width: 465px;
  font-family: "League Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 67px;
  letter-spacing: 0.01em;
  margin-bottom: 23px;
}

.stepup-para {
  max-width: 492px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #666666;
  margin-bottom: 43px;
}

.stepup-hero-btn {
  /* margin-bottom: 127px; */
  display: flex;
  gap: 40px;
}

.hero-get-start {
  padding: 12px 15px;
  background: linear-gradient(90deg, #980669 0%, #da0669 100%);
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  border: none;
}

.hero-contact-us {
  padding: 12px 17px;
  border: 1px solid #26344e;
  border-radius: 8px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

/* ------------Hero-Right----------------- */

.stepup-right {
  position: relative;
}

.hero-arrow-img {
  position: absolute;
  left: -200px;
  top: 0px;
}

.hero-right-img {
  width: 435px;
  height: 463px;
}

.stepup-hero-section-slider {
  position: relative;
  overflow: hidden;
}

.stepup-hero-section-slider .swiper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 100px;
  position: static;
  /* border: 1px solid red; */
}

/* ----------- Hero Slider ----------- */

.stepup-hero-section-slider .swiper-button-next,
.stepup-hero-section-slider .swiper-button-prev {
  border: 1px solid #da0669;
  border-radius: 5px;
  transform: rotate(135deg);
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 720px;
  /* bottom: 100px; */
}

.stepup-hero-section-slider .swiper-button-next {
  right: 81px;
}

.stepup-hero-section-slider .swiper-button-prev {
  left: 81px;
}

.stepup-hero-section-slider .swiper-button-next:after,
.stepup-hero-section-slider .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 20px;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  /* color: #F1AB27; */
  color: #da0669;
  padding: 20px;
  transform: rotate(-135deg) translateX(3px);
  font-weight: bold;
}

.stepup-hero-section-slider .swiper-button-prev:after {
  transform: rotate(-135deg) translateX(-2px);
}

.stepup-hero-section-slider .swiper-button-next:hover,
.stepup-hero-section-slider .swiper-button-prev:hover {
  background-color: #da0669;
}

.stepup-hero-section-slider .swiper-button-next:hover:after,
.stepup-hero-section-slider .swiper-button-prev:hover:after {
  color: #ffffff;
}

.stepup-hero-section-slider .swiper .swiper-pagination {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 665px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 50%;
}

.stepup-hero-section-slider
  .swiper
  .swiper-pagination
  .swiper-pagination-bullet-active {
  transform: translateY(2px);
  background: #da0669;
}

.stepup-hero-section-slider .swiper .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  gap: 20px;
  background: rgba(218, 6, 105, 0.5);
  /* opacity: 1; */
}

.stepup-hero-section-slider .swiper-pagination-bullet-active::after {
  content: "";
  position: relative;
  top: -3px;
  left: -4.8px;
  padding: 0px 11px;
  border: 2px solid rgba(218, 6, 105, 0.5);
  cursor: pointer;
  border-radius: 50%;
}

/* .stepup-hero-section-slider .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;

} */

/* -----------Ninja ---------------- */

.stepup-ninja {
  border-radius: 25px;
  background: #0c0c0c;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.15);
  width: 1360px;
  margin: 0 auto;
  padding: 87px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepup-ninja-left {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.stepup-ninja-hero-icon {
  width: 182px;
  height: 86px;
  margin-bottom: 20px;
}

.stepup-ninja-span {
  color: #fcfcfc;
  font-family: Cabin;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin-bottom: 50px;
}

.stepup-ninja-head-img {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 50px;
  max-width: 510px;
}

.stepup-ninja-heading {
  color: #fff;
  font-family: Poppins;
  font-size: 91px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 25% */
  letter-spacing: 0.3px;
  margin-bottom: 23px;
}

/* ------------Hero-Right----------------- */

.stepup-right {
  position: relative;
}

/* --------------------Hero Card----------------------- */

.hero-cards {
  padding: 52px 200px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

/* ----------------------Course we offer---------------------- */

.stepup-courses-section {
  background: #fafafa;
  overflow: hidden;
}

.courses-offer {
  padding: 50px 81px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.course-heading {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 43px;
  color: #26344e;
}

.course-para {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #666666;
  max-width: 880px;
  margin: 0 auto;
}

.step-up-course-cards {
  /* max-width: 1282px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 27px;
}

.step-up-course-cards .mySwiper {
  display: flex;
  justify-content: center;
}

.step-up-course-cards .swiper {
  position: static;
  max-width: 1282px;
  height: 470px;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* --------------------------Success Stories-------------------------- */

.success-stories-section {
  margin: 0 auto;
  overflow: hidden;
}

.success-stories {
  padding: 50px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
}

.success-stories-heading {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  color: #26344e;
  margin-bottom: 20px;
  max-width: 579px;
}

.success-stories-para {
  max-width: 673px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #666666;
  margin-bottom: 30px;
}

.success-stories-cards {
  position: relative;
}

/* ----------------------stepup-testimonials---------------------- */

.stepup-testimonials {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
}

.stepup-testimonial-heading {
  margin-top: 50px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 43px;
  color: #26344e;
}

.stepup-testimonial-para {
  width: 673px;
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #666666;
}

.stepup-testimonial-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 50px;
  position: relative;
}

/* ----------------Course-Pagination------------------ */

.courses-offer {
  position: relative;
}

.courses-offer .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  gap: 20px;
  background: rgba(218, 6, 105, 0.5);
  opacity: 1;
}

.courses-offer .swiper-pagination-bullet.swiper-pagination-bullet-active {
  /* transform: translateY(2px); */
  background: #da0669;
}

/* .courses-offer .step-up-course-cards
  .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  content: "";
  position: relative;
  top: -3.4px;
  left: -4.5px;
  padding: 0px 11px;
  border: 2px solid rgba(218, 6, 105, 0.5);
  cursor: pointer;
  border-radius: 50%;
} */

.step-up-course-cards .swiper .swiper-pagination {
  margin-bottom: 50px;
  /* position: absolute; */
  bottom: 0;
  /* display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; */
  /* border: 1px solid red; */
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0) translateX(-55px);
  z-index: 10;
  display: inline-block;
}

/* -------------------Pagination testimonials---------------------- */

.stepup-testimonials {
  position: relative;
  overflow: hidden;
}

.stepup-testimonial-cards .swiper .swiper-pagination {
  position: absolute;
  margin-bottom: 0px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.stepup-testimonial-cards
  .swiper
  .swiper-pagination
  .swiper-pagination-bullet-active {
  transform: translateY(2px);
  background: #da0669;
}

.stepup-testimonial-cards .swiper .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  gap: 20px;
  background: rgba(218, 6, 105, 0.5);
  opacity: 1;
}

.stepup-testimonial-cards .swiper-pagination-bullet-active::after {
  content: "";
  position: relative;
  top: -3.4px;
  left: -4.8px;
  padding: 0px 11px;
  border: 2px solid rgba(218, 6, 105, 0.5);
  cursor: pointer;
  border-radius: 50%;
}

.stepup-testimonial-cards .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* --------------------Success-navigation-buttons-styling--------------------- */

.success-stories-cards .swiper-button-next,
.success-stories-cards .swiper-button-prev {
  border: 1px solid #da0669;
  border-radius: 5px;
  transform: rotate(135deg);
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 110px;
}

.success-stories-cards .swiper-button-next {
  right: 0px;
}

.success-stories-cards .swiper-button-prev {
  left: 0px;
}

.success-stories-cards .swiper {
  position: static;
  max-width: 1100px;
  padding: 10px 0;
  /* margin: 0 70px; */
}

.success-stories-cards .swiper-button-next:after,
.success-stories-cards .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 20px;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  /* color: #F1AB27; */
  color: #da0669;
  padding: 20px;
  transform: rotate(-135deg) translateX(3px);
  font-weight: bold;
}

.success-stories-cards .swiper-button-prev:after {
  transform: rotate(-135deg) translateX(-2px);
}

.success-stories-cards .swiper-button-next:hover,
.success-stories-cards .swiper-button-prev:hover {
  background-color: #da0669;
}

.success-stories-cards .swiper-button-next:hover:after,
.success-stories-cards .swiper-button-prev:hover:after {
  color: #ffffff;
}

/* ---------------------Success-Stroies-Pagination------------------ */

.success-stories-cards .SwiperSlide {
  position: relative;
  margin: 5px 0px;
}

/* --------------ninja section ---------------- */

.stepup-ninja-section {
  background: #0c0c0c;
}

.stepup-ninja-section-inner {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
}

.stepupinnja-head {
  color: #fff;
  font-family: Cabin;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.stepup-primary-color {
  color: #da0669;
}

.stepupinnja-span {
    max-width: 627px;
  color: #f6f6f6;
  text-align: center;
  font-family: Cabin;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3px;
}

.stepup-section-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 235px;
    margin-bottom: 30px;
}

.stepupninja-inner-span{
    margin-bottom: 32px;
}

.ninja-bottom-head-img{
    margin-top: 52px;
    display: flex;
    align-items: center;
    max-width: 536px;
    margin-bottom: 80px;
}



/* ------------------------------------------------------- */
/* ---------------------Resposive-Design------------------- */
/* ------------------------------------------------------- */

@media (min-width: 431px) {
  .heroh1icon {
    display: none;
  }

  .responsiveimg {
    display: none;
  }
}

@media (max-width: 426px) {
  .stepup-hero-section {
    padding-top: 100px;
  }
  .stepup {
    width: 322px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding: 0px;
    padding-top: 10px;
    margin: 0 auto;
    /* border: 1px solid red; */
  }

  .responsiveimg {
    display: none;
  }

  .stepup-hero-section-slider .swiper {
    max-width: 352px;
    margin: 0 auto;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 100px;
    position: static;
    /* border: 1px solid red; */
  }

  .stepup-right .hero-arrow-img {
    display: none;
  }
  .stepup-right .hero-right-img {
    max-width: 250px;
    height: 250px;
  }

  .stepup-hero-section {
    padding-top: 50px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: none;
    width: 302px;
    margin: 0 auto;
  }

  .stepup-hero-icon {
    width: 70px;
    height: 26px;
    margin-top: 25px;
  }

  .stepup-hero-section .stepup-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .stepup-hero-heading {
    width: 232px;
    font-weight: 400;
    font-size: 34px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  .stepup-para {
    width: 286px;
    font-family: "Cabin";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #727272;
  }

  .stepup-hero-btn {
    display: flex;
    gap: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .hero-get-start {
    padding: 7px 0px;
    width: 141px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 5px;
  }

  .hero-contact-us {
    padding: 7px 0px;
    width: 141px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 5px;
    color: #000000;
  }

  .stepup-hero-section-slider .swiper-slide {
    padding-top: 30px;
    margin-right: 0;
    /* border: 1px solid red; */
    width: 100%;
  }

  .stepup-hero-section-slider .swiper-button-next,
  .stepup-hero-section-slider .swiper-button-prev {
    border: 1px solid #da0669;
    border-radius: 5px;
    transform: rotate(135deg);
    width: 33px;
    height: 33px;
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 720px;
    /* bottom: 100px; */
  }

  .stepup-hero-section-slider .mySwiper .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    gap: 10px;
    background: rgba(218, 6, 105, 0.5);
    opacity: 1;
  }

  .stepup-hero-section-slider .mySwiper .swiper-pagination-bullet-active {
    transform: translateY(2px);
    background: rgba(218, 6, 105, 0.5);
    height: 10px;
    width: 10px;
  }

  .stepup-hero-section-slider
    .mySwiper
    .swiper-pagination-bullet-active::after {
    content: "";
    position: absolute;
    padding: 8px;
    top: -5px;
    left: -4.7px;
    border: 2px solid rgba(218, 6, 105, 0.5);
    cursor: pointer;
    border-radius: 50%;
  }

  .stepup-hero-section-slider .swiper .swiper-pagination {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 25px;
  }

  /* -----------Ninja ---------------- */

  .stepup-ninja {
    border-radius: 25px;
    background: #0c0c0c;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.15);
    width: 340px;
    margin: 0 auto;
    padding: 70px 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .stepup-ninja-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
  }

  .stepup-ninja-hero-icon {
    width: 84px;
    height: 39px;
    margin-bottom: 20px;
  }

  .stepup-ninja-span {
    color: #fcfcfc;
    font-family: Cabin;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.1px;
    margin-bottom: 30px;
  }

  .stepup-ninja-head-img {
    max-width: 465px;
    display: flex;
    align-items: center;
    justify-content: c;
    gap: 4px;
    margin-bottom: 50px;
  }

  .stepup-ninja-head-img img {
    width: 34px;
    height: 31px;
    transform: translateY(-6px);
  }

  .stepup-ninja-heading {
    color: #fff;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 25% */
    letter-spacing: 0.1px;
    margin-bottom: 23px;
  }

  .stepup-ninja-right {
    margin-top: 40px;
    width: 223px;
    height: 211px;
  }

  .stepup-ninja-right img {
    width: 100%;
  }

  /* --------------Hero-Card------------------- */

  .hero-cards {
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    margin: 0px auto;
    margin-right: 0px;
    max-width: 426px;
  }

  /* -----------------Course Section------------------ */

  .step-up-course-cards .swiper .swiper-pagination {
    display: none;
  }

  .step-up-course-cards .mySwiper {
    display: flex;
    justify-content: center;
    display: flex;
    gap: 15px;
    overflow: hidden;
    margin: 0 15px;
  }

  .step-up-course-cards .swiper {
    position: static;
    height: 420px;
    width: 850px;
    overflow: hidden;
    margin: 0px auto;
  }

  .courses-offer {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    position: relative;
    /* max-width: 426px; */
    padding: 0px 0px;
    padding-top: 30px;
    overflow: hidden;
  }

  .course-heading {
    width: 169px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 0 auto;
  }

  .course-para {
    width: 345px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0 auto;
  }

  .courses-offer .step-up-course-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px;
    overflow: hidden;
    margin-right: 100px;
    border: 1px solid red;
  }

  .step-up-course-cards .swiper .swiper-wrapper{
    border: 2px solid black;
  }

  /* ------------------Success Stories------------------------ */

  .success-stories {
    padding: 0px;
    display: flex;
    max-width: 426px;
    overflow: hidden;
  }

  .success-stories-heading {
    margin-top: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  .success-stories-para {
    width: 319px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
  }

  .success-stories-cards {
    overflow: hidden;
    height: 500px;
    max-width: 426px;
    margin: 0 auto;
  }

  .success-stories-cards .swiper {
    position: static;
    margin: 0px auto;
    overflow: hidden;
    max-width: 350px;
  }

  .success-stories-cards .swiper-slide {
    position: static;
    margin: 0px auto;
    /* overflow: hidden; */
  }

  .success-stories-cards .swiper-button-next,
  .success-stories-cards .swiper-button-prev {
    border: 1px solid #da0669;
    border-radius: 5px;
    transform: rotate(135deg);
    width: 17.68px;
    height: 17.68px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 50px;
  }

  .success-stories-cards .swiper-button-next {
    left: 195px;
    top: 470px;
  }

  .success-stories-cards .swiper-button-prev {
    left: 140px;
    top: 470px;
  }

  .success-stories-cards .swiper-button-next:after,
  .success-stories-cards .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #da0669;
    padding: 8px;
    transform: rotate(-135deg) translateX(1.5px);
    font-weight: bold;
    z-index: 1000;
  }

  .success-stories-cards .swiper-button-prev:after {
    transform: rotate(-135deg) translateX(-1.5px);
  }

  .success-stories-cards .swiper-button-next:hover,
  .success-stories-cards .swiper-button-prev:hover {
    background-color: #da0669;
  }

  .success-stories-cards .swiper-button-next:hover:after,
  .success-stories-cards .swiper-button-prev:hover:after {
    color: #fff;
  }

  /* ----------------------stepup-testimonials---------------------- */

  .stepup-testimonials {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fafafa;
    max-width: 426px;
    padding: 15px;
  }

  .stepup-testimonial-heading {
    margin-top: 30px;
    font-family: "Cabin";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #26344e;
  }

  .stepup-testimonial-para {
    width: 345px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-family: "Cabin";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3px;
  }

  .stepup-testimonial-cards {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    max-width: 426px;
  }

  .stepup-testimonials {
    position: relative;
    overflow: hidden;
  }

  .stepup-testimonial-cards .swiper {
    position: static;
    max-width: 426px;
    margin-bottom: 40px;
    overflow: hidden;
    padding-bottom: 30px;
    padding-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .stepup-testimonial-cards .mySwiper .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    gap: 10px;
    background: rgba(218, 6, 105, 0.5);
    opacity: 1;
  }

  .stepup-testimonial-cards .mySwiper .swiper-pagination-bullet-active {
    transform: translateY(2px);
    background: rgba(218, 6, 105, 0.5);
    height: 10px;
    width: 10px;
  }

  .stepup-testimonial-cards .mySwiper .swiper-pagination-bullet-active::after {
    content: "";
    position: absolute;
    padding: 8px;
    top: -5px;
    left: -4.7px;
    border: 2px solid rgba(218, 6, 105, 0.5);
    cursor: pointer;
    border-radius: 50%;
  }

  .stepup-testimonial-cards .swiper .swiper-pagination {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 25px;
  }


  /* --------------ninja section ---------------- */

.stepup-ninja-section {
    background: #0c0c0c;
  }
  
  .stepup-ninja-section-inner {
    max-width: 343px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;
  }
  
  .stepupinnja-head {
    color: #fff;
    font-family: Cabin;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  .stepup-primary-color {
    color: #da0669;
  }
  
  .stepupinnja-span {
      max-width: 627px;
    color: #f6f6f6;
    text-align: center;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin-bottom: 50px;
  }
  
  .stepup-section-bottom{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 30px;
      margin-bottom: 30px;
  }
  
  .stepupninja-inner-span{
      margin-bottom: 20px;
      font-size: 16px;
  }
  
  .ninja-bottom-head-img{
      margin-top: 32px;
      display: flex;
      align-items: center;
      max-width: 536px;
      margin-bottom: 40px;
  }

  .ninja-bottom-head-img img{
    transform: translateY(-6px);
  }

  .stepupninja-inner-head{
    font-size: 61px;
  }

  .stepup-section-bottom-right{
    max-width: 304px;
  }
  .stepup-section-bottom-right img{
    width: 100%;
  }
  
}

.contactUs {
    margin-top: 82px;
    display: flex;
    align-items: center;
    gap: 66px;
    margin-bottom: 0;
}

.contactUs-left {
    width: 738px;
    background: url('../Contact/contact-bg.png');
    background-repeat: no-repeat;
    background-position: right;
    height: 834px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    gap: 13px;
    padding-left: 85px;
}

.contactUs-heading {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 67px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.contactUs-para {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    max-width: 511px;
}

.contactUs-form-heading {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #26344E;
    margin-bottom: 20px;
}

.contactUs-form {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 562px;
}

.contact-label-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contactUs-form label {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
}

.contactUs-form label span {
    color: #F1AB27;
}

.contactUs-form input {
    padding: 8px 15px;
    width: 100%;
    border: 0.5px solid #B9B9B9;
    border-radius: 5px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #989898;
}
.contactUs-form input:focus,.contactUs-form textarea:focus{
    border: 0.5px solid #F1AB27;
    outline: none;
}

.contactUs-form textarea {
    border: 0.5px solid #B9B9B9;
    border-radius: 5px;
    padding: 10px 15px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #989898;
}

.contactUs-form .btn-submit {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
    background: #26344E;
    border-radius: 8px;
    padding: 11px 41px;
    max-width: 135px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactUs-form .btn-submit:disabled{
    background: #485670;
    cursor: not-allowed;
}
.error{
    font-size: 12px;
    color: red;
}
/* ---------- Media Queries for Mobile -------- */

@media(max-width:426px) {
    .error{
        font-size: 9px;
    }
    .contactUs {
        margin-top: 52px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        max-width: 426px;

    }

    .contactUs-left {
        width: 100%;
        background-size: cover;
        height: 423px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        padding-left: 0px;
    }

    .contactUs-heading {
        font-family: 'League Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 33px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        max-width: 223px;
        margin: 0 auto;
    }

    .contactUs-para {
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
        max-width: 267.66px;
        margin: 0 auto;
        text-align: center;
    }

    .contactUs-right {
        width: 345px;
        margin: 0 auto;
    }

    .contactUs-form-heading {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 16px;
    }

    .contactUs-form {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 345px;
    }

    .contact-label-input {
        display: flex;
        flex-direction: column;
        gap: 10px;

    }

    .contactUs-form label {
        font-size: 14px;
        line-height: 18px;
    }

    .contactUs-form label span {
        color: #F1AB27;
    }

    .contactUs-form input {
        padding: 11px 15px;
        width: 100%;
        border-radius: 5px;
        font-size: 14px;
        line-height: 18px;
    }

    .contactUs-form textarea {
        font-size: 12px;
        line-height: 18px;
    }

    .contactUs-form .btn-submit {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        background: #26344E;
        border-radius: 5px;
        padding: 13.5px 42px;
        max-width: 122px;
        margin-top: 10px;
        margin-bottom: 50px;
    }
}
.stepup-testimonials-card {
    margin-bottom: 43px;
    max-width: 475px;
    height: 237px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 2px solid #ECECEC;
    border-radius: 10px;
    transition: background .5s ease;

}

.stepup-testimonials-card:hover {
    background: linear-gradient(90deg, #990669 0%, #D80669 100%);;
    border-radius: 10px;
    color: #FFFFFF;

}

.stepup-testimonials-top {
    margin-bottom: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepup-testimonials-card img {
    margin-top: 36px;
    margin-left: 35px;
    margin-right: 30px;

}

.stepup-testimonials-card p {
    max-width: 307px;
    height: 96px;
    margin-top: 35px;
    margin-right: 28px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #666666;
}
.stepup-testimonials-card:hover p{
    color: #FFFFFF;
}

.stepup-testimonials-card h1 {
    margin-left: 135px;
    margin-bottom: 23px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.3;
}
.stepup-testimonials-card:hover .testimon-headeing{
    color: #FFFFFF;
    opacity: 1;
}

@media (max-width: 431px) {

    .stepup-testimonials-card {
        padding: 0px 15px;
        padding-top: 15px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        width: 340px;
        height: 158px;

    }

    .stepup-testimonials-card:hover {
        box-shadow: 0px 20px 50px rgba(59, 90, 136, 0.05);
        border: none;

    }

    .stepup-testimonials-top {

        margin-bottom: 0px;
        justify-content: flex-start;

    }

    .stepup-testimonials-card img {
        width: 60px;
        height: 60px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 10px;

    }

    .stepup-testimonials-card p {
        width: 240px;
        height: 91px;
        margin-top: 0px;
        margin-right: 0px;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #727272;
    }

    .stepup-testimonials-card h1 {
        margin-top: 15px;
        margin-left: 71px;
        margin-bottom: 1px;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 2px;
    }

}
/* ------- Footer Section ---------- */

.footer-section {
    background: #1C2A44;
}

.footer {
    /* padding-left: 130px;
    padding-right: 129px; */
    padding-top: 66px;
    padding-bottom: 42px;
    display: flex;
    flex-wrap: wrap;
    gap: 67px;
    /* width: 100%; */
    max-width: 1185px;
    margin: 0 auto;
}

.footer-left {
    max-width: 291px;
    flex: 1 1 291px;
}

.footer-left img {
    margin-bottom: 20px;
}

.footer-left img.imx-logo2 {
    display: none;
}

.footer-left-para {
    margin-bottom: 51px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.footer-social-icons {
    display: flex;
    gap: 10px;
}

.footer-middle {
    max-width: 363px;
    flex: 1 1 363px;
    display: flex;
    justify-content: center;
    gap: 47px;
    margin-right: 20px;
}

.footer-middle-links {
    max-width: 104px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-middle-span {
    margin-bottom: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
}

.footer-middle-links a {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #D6D6D6;
}

.footer-middle-links a:hover {
    color: #F1AB27;
}

.footer-right {
    max-width: 363px;
    display: flex;
    flex: 1 1 363px;
    flex-direction: column;
    gap: 24px;
}

.footer-right-icons-details {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-right-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
    transform: translateY(5px);
}

.footer-right-details span.footer-contacts {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CACACA;
}

.footer-right-details span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.footer-right-details span.mt-3 {
    margin-top: 3px;
}

.footer-right-icons-details div.translateY-5 {
    transform: translateY(17px);
}

.breakline{
    display: none;
}
.about-contact-career-links{
    display: none;
}
.about-career-contact-list{
    display: none;
}


.footer-bottom {
    background: #1A2538;
}

.footer-bottom span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    background: #1A2538;
    padding: 15px 0px;
    display: flex;
    justify-content: center;
}

.social-media-icons-bottom {
    display: none;
}

/* ------------- Media Queries for mobile ----------- */
@media(max-width:426px) {
    .footer {
        padding: 20px 19px;
        padding-bottom: 11px;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 30px;
        width: 100%;
    }

    .footer-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 307px;
        margin: 0 auto;
        flex: 1 1 307px;
    }

    .footer-left img.imx-logo1 {
        display: none;
        width: 131.74px;
        height: 24px;
        margin-bottom: 10px;
    }

    .footer-left img.imx-logo2 {
        width: 131.74px;
        height: 24px;
        margin-bottom: 10px;
        display: block;
    }

    .footer-left-para {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        width: 307px;
        text-align: center;
        margin: 0 auto;
    }

    .footer-social-icons {
        display: flex;
        align-items: center;
        gap: 8px;
        display: none;
    }

    .footer-middle {
        display: none;
    }

    .footer-right {
        max-width: 338px;
        display: flex;
        flex: 1 1 338px;
        flex-direction: column;
        gap: 24px;
        border: 1ps solid red;
    }

    .footer-right-icons-details {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .footer-right-icons-details img {
        width: 38px;
    }

    .footer-right-details {
        display: flex;
        flex-direction: column;
        gap: 2px;
        transform: translateY(5px);
    }

    .footer-right-details span.footer-contacts {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #CACACA;
    }

    .footer-right-details span {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        color: #FFFFFF;
        max-width: 287px;
    }

    .breakline {
        width: 100%;
        border: 0.5px solid #747474;
        margin: 0 auto;
        display: block;
    }

    .about-contact-career-links {
        text-align: center;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        width: 100%;
        margin-top: -15px;
        display: block;
    }

    .about-career-contact-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -15px;
    }

    .about-career-contact-list a {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .social-media-icons-bottom {
        display: flex;
        justify-content: center;
        gap: 5px;
        width: 100%;
        margin-top: -15px;
    }



    .footer-bottom {
        background: #1A2538;
    }

    .footer-bottom span {
        font-weight: 400;
        font-size: 12px;
        padding: 9px 0px;
    }

}
.stepup-course-cards {
    max-width: 408px;
    height: 402px;
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 15px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    transition: box-shadow .2s ease;
    padding: 30px 30px;
    
}

.stepup-course-cards:hover {
    box-shadow: 0px 20px 50px rgba(59, 90, 136, 0.05);

}

.stepUp-course-card-icon-title{
    display: flex;
    align-items: center;
    gap: 20px;
}

.basic-level-course, .advance-level-course{
    margin-top: 5px;
    display: flex;
width: 115px;
padding: 5px 10px;
justify-content: center;
align-items: center;
color: #FFF;
font-family: Cabin;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 25px;

}

.basic-level-course{
    background: #DEB268;
}
.advance-level-course{
    background: #990669;
    width: 133px;
}

.stepup-course-cards img {
    width: 60px;
}

.stepup-course-cards h1 {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    /* max-width: 310px; */
    width: 100%;
}

.stepup-course-cards p {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.3px;
    color: #666666;
    max-width: 348px;
  height: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  display: -ms-flexbox;
  -ms-flex-direction: column;

}

.stepup-course-cards button {
    position: absolute;
    bottom: 25px;
    left: 30px;
    right: 30px;
    padding: 10px 0;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    background: #26344E;
    border-radius: 4px;
    color: #FFFFFF;

}

/* ----------------------styling for mobile--------------------- */

/* ----------------------------Responsive----------------------- */

@media (max-width: 431px) {

    .stepup-course-cards {
        width: 257px;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        border-radius: 5px;
        padding: 30px 15px;
        height: 346px;

    }

    .stepup-course-cards:hover {
        box-shadow: 0px 20px 50px rgba(59, 90, 136, 0.05);

    }

    .basic-level-course, .advance-level-course{
        margin-top: 5px;
        display: flex;
    width: 85px;
    padding: 5px 7px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Cabin;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 25px;
    
    }
    .advance-level-course{
        width: 100px;
    }

    .stepup-course-cards img {
        width: 46px;
        margin-bottom: 8px;
        display: inline;

    }

    .stepup-course-cards h1 {
        /* margin-top: 20px; */
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* margin-left: 0px; */

    }

    .stepup-course-cards p {
        width: 217px;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        letter-spacing: 0.3px;
        color: #727272;
        -webkit-line-clamp: 9;
        height: 161px;

    }

    .stepup-course-cards button {
        bottom: 30px;
        left: 15px;
        right: 15px;
        padding: 6px 0px;
        font-size: 12px;
    }

}
.success-card {
    padding: 15px;
    display: flex;
    gap: 80px;
    max-width: 933px;
    height: 305px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.success-card-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.success-left-img {
    width: 211px;
}

.success-card-left-heading {
    font-family: 'Big Shoulders Display';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 8px;
}

.success-card-left-para {
    width: 294px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #727272;

}

.success-card-right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 520px;
}

.success-fiver-img {
    width: 62px;
    margin-bottom: 10px;
}

.success-center {
    display: flex;
    justify-content: space-between;
}

.success-quote-img {
    width: 24px;
    margin-bottom: 13px;
}

.success-fh {
    display: flex;

}

.success-card-clright-heading {
    font-family: 'Big Shoulders Display';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 21px;
}

.success-card-clgreen-heading {
    font-family: 'Big Shoulders Display';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    color: #14A800;
}

.success-card-crright-heading {
    font-family: 'Big Shoulders Display';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    color: #000000;

}

.success-card-right-para {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #727272;
}

@media (min-width: 431px) {
    .success-card-responsive-crright-heading {
        display: none;
    }
}





/* -----------------Success-Responsive-------------------- */

@media (max-width: 426px) {
    .success-card {
        max-width: 326px;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: none;
        gap: 10px;
        margin: 0 auto;
        padding: 0;
    }

    .success-card-left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .success-left-img {
        width: 211px;
    }

    .success-card-left-heading {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 0px;
    }

    .success-card-left-para {
        display: none;

    }

    .success-card-right {
        width: 326px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .success-fiver-img {
        display: none;
    }

    .success-center {
        display: flex;
        justify-content: center;
    }

    .success-quote-img {
        display: none;
    }

    .success-fh {
        display: flex;
        max-width: 336px;

    }

    .success-card-clright-heading {
        font-family: 'Big Shoulders Display';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    .success-card-clgreen-heading {
        columns: #14A800;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;

    }

    .success-card-crright-heading {
        display: none;
    }

    .success-card-responsive-crright-heading {
        font-family: 'Big Shoulders Display';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: #000000;
    }

    .success-card-right-para {
        margin-bottom: 10px;
        max-width: 326px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
    }




}
.features-card {
  /* flex: 1 1 360px; */
  width: 360px;
  /* padding: 60px 48px; */
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 48px;
  transition: box-shadow 0.5s ease, background 0.5s ease;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  position: relative;
  height: 400px;
}

.features-card:hover {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 20px 50px rgba(59, 90, 136, 0.05);
}
.features-card .features-card-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  box-shadow: 0px 5px 50px rgba(91, 132, 193, 0.1);
  border-radius: 50%;
  background-color: #ffffff;
  margin-bottom: 30px;
}
.features-card:hover .features-card-img-box img {
  box-shadow: 0px 5px 50px rgba(91, 132, 193, 0.1);
  border-radius: 60px;
}

.features-card .features-card-img-box img {
  /* margin-bottom: 30px; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.features-card h1 {
  margin-bottom: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #0f2137;
}

.features-card p {
  max-width: 282px;
  margin-bottom: 20px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #343d48;
}
.features-card .learn-more-service{
    position: absolute;
    bottom: 15px;
}
.features-card .learn-more-service a {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.features-card .learn-more-service a .icon-font {
  font-size: 12px;
}

/* ------------ Media Queries for Mobile ------------- */

@media (max-width: 426px) {
  .features-card {
    width: 257px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    padding: 15px;
    transition: box-shadow 0.5s ease, background 0.5s ease;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    position: relative;
  }

  .features-card:hover {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 20px 50px rgba(59, 90, 136, 0.05);
    border-radius: 0px;
  }

  .features-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
    margin: 0 auto;
  }

  .features-card h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #0f2137;
    margin-bottom: 10px;
    text-align: center;
  }

  .features-card p {
    max-width: 227px;
    margin-bottom: 30px;
    font-family: "Cabin";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #343d48;
  }

  .features-card a {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    right: 0;
    left: 0;
    position: absolute;
    bottom: 15px;
  }

  .features-card a .icon-font {
    font-size: 10px;
  }
}

.digi-hero-card {
    /* max-width: 191px; */
    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 12px 15px;
}

.digi-hero-card-head {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #666666;
    margin-bottom: 10px;
    
}

.digi-hero-card-bottom{
    display: flex;
    justify-content: center;
    flex-direction: flex-end;
    transform: translateX(-15px);
}

.digi-hero-card-img1{
    transform: translateX(30px);
}

.digi-hero-card-img2{
    transform: translateX(20px);
}

.digi-hero-card-img3{
    transform: translateX(10px);
}

.digi-hero-card-img4{
    z-index: 100;
}

/* -------- Media Queries for mobile -------- */
@media(max-width:426px){
    .digi-hero-card {
        /* max-width: 191px; */
        width: 100%;
        background: #FFFFFF;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4.6px;
        padding: 5px 7px;
    }
    
    .digi-hero-card-head {
        font-size: 7.4601px;
        line-height: 9px;
        margin-bottom: 5px; 
    }
    
    .digi-hero-card-bottom{
        display: flex;
        justify-content: center;
        flex-direction: flex-end;
        transform: translateX(-15px);
    }
    
    .digi-hero-card-img1{
        transform: translateX(20px);
        width: 14px;
        height: 14px;
    }
    
    .digi-hero-card-img2{
        transform: translateX(15px);
        width: 14px;
        height: 14px;
    }
    
    .digi-hero-card-img3{
        transform: translateX(10px);
        width: 14px;
        height: 14px;
    }
    
    .digi-hero-card-img4{
        transform: translateX(5px);
        z-index: 100;
        width: 14px;
        height: 14px;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&family=Big+Shoulders+Display:wght@600&family=DM+Sans:wght@400;500;700&family=League+Gothic&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
.max-width-1440{
  max-width: 1440px;
  margin: 0 auto;
  /* border: 1px solid red; */
}

.primary-blue-color{
  color: #1560AE;
}
body{
  overflow-x: hidden;
}
button{
  border: none;
}



.intern-card {
    display: flex;
    align-items: center;
    gap: 40px;

}

.intern-details {
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    width: 141px;
}

.intern-batch {
    position: absolute;
    background: #F1AB27;
    border-radius: 15px 0px 0px 15px;
    top: 15;
    right: 0;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 13px;
}

.intern-batch-no {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: #FFFFFF;
}

.intern-detail-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: #3C3C3C;
    margin-top: 43px;
    margin-bottom: 6px;
}

.intern-detail-para {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #919191;
}

/* --------- Media Queries for Mobile ---------- */

@media(max-width:426px){
    .intern-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;
    
    }

    .intern-card img{
        width: 74px;
    }
    
    .intern-details {
        background: #FFFFFF;
        border: 1px solid #C8C8C8;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 15px;
        position: relative;
        width: 284px;
        height: 65px;
        margin-top: -4px;
    }

    .intern-head-detail{
        position: absolute;
    }
    
    .intern-batch {
        position: absolute;
        background: #F1AB27;
        border-radius: 15px 0px 0px 15px;
        top: 21px;
        padding-bottom: 5px;
        padding-left: 12px;
        padding-right: 13px;
    }
    
    .intern-detail-heading {
        margin-top: 0px;
        margin-bottom: 6px;
    }
}